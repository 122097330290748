<template>
  <Transition name="pop">
    <div v-if="show || visible" class="c-topic-search-panel">
      <SearchBar
        v-model="keyword"
        :back="onCancel"
        :placeholder="$t('users.search')"
      />

      <main>
        <JoLoadMore
          ref="loadmore"
          :auto-load="false"
          :show-bottom="Boolean(keyword && list.length)"
          @onRefresh="onRefresh"
          @onLoadMore="onLoadMore"
        >
          <template v-if="!keyword || list.length">
            <div v-show="!list.length" class="title-bar">{{ $t('users.friends') }}</div>
            <ul class="search-list">
              <li
                v-for="user in users"
                :key="user.id"
                class="item"
                @click="onSelect(user)"
                v-html="highlight(user.name)"
              />
            </ul>
          </template>
          <div v-else class="m-no-find" />
        </JoLoadMore>
      </main>
    </div>
  </Transition>
</template>

<script>
  import { mapState } from 'vuex'
  import { limit } from '@/api'
  import * as api from '@/api/user'
  import SearchBar from '@/components/common/SearchBar.vue'

  export default {
    name: 'UserSearchPanel',
    components: {
      SearchBar,
    },
    props: {
      show: { type: Boolean, default: false },
    },
    data () {
      return {
        visible: false,
        keyword: '',
        list: [],
      }
    },
    computed: {
      ...mapState('user', {
        friends: 'friends',
      }),
      users () {
        return this.list.length ? this.list : this.friends
      },
    },
    watch: {
      keyword (val) {
        if (val) {
          this.$refs.loadmore.beforeRefresh()
          this.onRefresh()
        } else {
          this.$refs.loadmore.afterRefresh(true)
          this.list = []
        }
      },
    },
    mounted () {
      if (!this.friends.length) this.$store.dispatch('user/getUserFriends')
    },
    methods: {
      async onRefresh () {
        const { data } = await api.getUserList({ name: this.keyword })
        this.list = data
        this.$refs.loadmore.afterRefresh(data.length < limit)
      },
      async onLoadMore () {
        const lastUser = [...this.list].pop() || {}
        const { data } = await api.getUserList({ name: this.keyword, since: lastUser.id })
        this.list.push(...data)
        this.$refs.loadmore.afterRefresh(data.length < limit)
      },
      highlight (name) {
        const regex = new RegExp(`(${this.keyword})`, 'ig')
        return name.replace(regex, '<span class="primary">$1</span>')
      },
      open () {
        this.visible = true
        this.keyword = ''
      },
      close () {
        this.visible = false
      },
      onCancel () {
        this.$emit('cancel')
        this.close()
      },
      onSelect (user) {
        this.$emit('select', user)
        this.close()
      },
    },
  }
</script>

<style lang="less" scoped>
.c-topic-search-panel {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 10;

  .title-bar {
    padding: 20px 30px;
    font-size: 26px;
    background-color: #ededed;
    color: @text-color3;
  }

  .search-list {
    border-top: 1px solid @border-color; /* no */
    margin-top: -1px; /* no */

    .item {
      padding: 30px;
      border-bottom: 1px solid @border-color; /* no */
    }
  }

  .m-no-find {
    height: 800px;
  }
}
</style>

<style lang="less">
.c-topic-search-panel {
  .primary {
    color: @primary;
  }
}
</style>
