<template>
  <div class="c-topic-selector">
    <FormSelectItem
      :label="$t('release.add_at')"
      @click="$refs.atUserPanel.open()"
    />
    <!-- <ul v-else class="topic-select">
      <li
        v-for="user in users"
        :key="`selected-topic-${user.id}`"
        class="topic-item"
      >
        <svg class="m-style-svg m-svg-small"><use xlink:href="#icon-delete" /></svg>
        {{ user.name }}
      </li>
      <li
        v-if="users.length < 5"
        class="topic-add"
        @click="$refs.atUserPanel.open()"
      >
        <svg class="m-style-svg m-svg-small"><use xlink:href="#icon-plus" /></svg>
      </li>
    </ul> -->

    <UserSearchPanel ref="atUserPanel" @select="addUser" />
  </div>
</template>

<script>
  import UserSearchPanel from '@/components/UserSearchPanel.vue'

  export default {
    name: 'AtSelector',
    components: {
      UserSearchPanel,
    },
    props: {
      value: { type: String, default: () => '' },
    },
    methods: {
      addUser (user) {
        this.$emit('input', this.value + '­@' + user.name + ' ')
      },
    },
  }
</script>

<style lang="less" scoped>
.c-topic-selector {
  /deep/ .c-form-item {
    color: inherit;

    > label {
      white-space: nowrap;
    }
  }

  .topic-select {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 20px;
    border-bottom: 1px solid @border-color;

    > li {
      display: inline-flex;
      align-items: center;
      height: 50px;
      margin-left: 30px;
      margin-top: 20px;
      border-radius: 8px;
    }

    .topic-item {
      position: relative;
      padding: 0 16px;
      background-color: #cdcdcd;
      font-size: 24px;
      color: #fff;

      .m-svg-small {
        position: absolute;
        top: -16px;
        left: -16px;
        width: 32px;
        height: 32px;
        padding: 6px;
        background-color: #999;
        border-radius: 20px;
      }
    }

    .topic-add {
      padding: 0 30px;
      border: 1px solid @border-color;

      .m-svg-small {
        width: 30px;
        height: 30px;
        color: @text-color3;
      }
    }
  }
}
/deep/.jo-loadmore-main {
  margin-top: 0.9rem;
}
</style>
